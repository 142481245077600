.backgroundImg {
  filter: brightness(0.7);
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.5s;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.spinnerWrp {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcfcfc;
  background-image: linear-gradient(34deg, #111112 11%, #0c031d 100%);
}

.about__container {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.features {
  width: 35%;
  background: rgba(11, 5, 5, 0.69);
  border: 5px solid rgba(0, 0, 0, 0.53);
  border-radius: 0 30px 30px 0;
  white-space: pre-wrap;
  margin-top: 5%;
  margin-left: -3px;
}
.features__titleWrp {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.feature__icons {
  margin-right: 5px;
}
.features__wrp {
  width: 90%;
  margin-left: 30px;
}

.features__firstBox {
  margin: 5% 0 0 30px;
}

.features__thirdBox {
  margin: 0 0 5% 30px;
}

.features__title {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  color: #cccadc;
}
.features__text {
  text-align: left;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 30px;
  margin: 30px 0;
  color: #cccadc;
}

.aboutUs {
  background: rgba(0, 0, 0, 0.67);
  border: 5px solid rgba(0, 0, 0, 0.16);
  border-radius: 30px 0 0 30px;
  width: 50%;
  margin: 30% 0 30%;
}

.aboutUs__title {
  margin: 50px auto 30px;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 46px;
  text-align: center;
  color: #cccadc;
}

.aboutUs__text {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  margin: 20px 40px;
  color: #cccadc;
}
@media screen and (min-width: 900px) {
  .about__container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    align-items: center;
  }
  .features {
    margin-top: 100px;
    width: 32%;
    height: 70%;
    margin: 100px 0 30px;
  }

  .features__text {
    font-size: 19px;
  }
  .about__feturesList {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
  }
  .features__wrp {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }
  .aboutUs {
    margin: 0;
    width: 55%;
    height: min-content;
  }
}

@media screen and (max-width: 700px) {
  .about__container {
    height: 100%;
    width: 100%;
    display: grid;
    min-height: 100%;
    justify-items: stretch;
  }
  .aboutUs,
  .features {
    width: 100%;
    border-radius: 0;
    margin: 80px 0 0 0;
    border-right: 0;
    border-left: 0;
  }
  .features__text,
  .aboutUs__text {
    font-size: 20px;
    text-align: center;
    margin: 20px 5px;
  }
  .about__feturesList {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .features__wrp {
    margin: 0;
  }

  .features__titleWrp {
    justify-content: center;
  }
}
