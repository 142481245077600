.backgroundImg {
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.5s;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.spinnerWrp {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcfcfc;
  background-image: linear-gradient(34deg, #111112 11%, #0c031d 100%);
}
.pricesWrp {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 105px;
  height: min-content;
}
.pricesNav {
  height: 300px;
  background: linear-gradient(270deg, #0e0918c2 0%, #020101a3 100%);
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  margin: 0 0 0 auto;
}
.navItem {
  transition: all 0.5s;
  background: none;
  border: none;
  font-size: 19px;
  height: 25%;
  width: 100%;
  color: #adacad;
}
.activeNavItem {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  background: linear-gradient(298deg, #1e1e27 12%, #130a07 100%);
  color: #acacac;
  box-shadow: 0px 0px 14px 9px rgba(0, 0, 0, 0.75);
}
.priceListBox {
  width: 60%;
  min-height: 300px;
  height: min-content;
  background: linear-gradient(270deg, #0b0503 0%, #1e1e27 100%);
  color: #acacac;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin: 0 auto 0 0;
  box-shadow: 0px 0px 17px 12px rgba(0, 0, 0, 0.75);
  align-items: center;
}

.pricelistItem {
  height: min-content;
  transition: all 0.5s;
  width: 100%;
  flex-shrink: 0;
  text-align: center;
}
.pricelistText {
  font-size: 20px;

  width: 60%;
}

.pricelistBox {
  width: 100%;
}
.shoesList {
  display: flex;
  justify-content: space-around;
}
.pricelist__container {
  min-height: 100vh;
  overflow: hidden;
  font-family: 'Oswald', sans-serif;
}
li {
  font-family: 'Oswald';
  font-weight: 500;
  color: #c0b1e2;
  font-size: 18px;
}

img {
  padding-left: 39px;
}

.pl__extraContainer {
  height: 16%;
  background: rgba(0, 0, 0, 0.63);
  border: 3px solid #4f4949;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 93%;
  margin-bottom: 20px;
}
.extraText {
  font-family: 'Josefin Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  margin: 15px;
  color: rgba(255, 255, 255, 0.67);
}
.extraButton {
  font-family: 'Josefin Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  color: rgba(255, 255, 255, 0.67);
  color: #fff;
  height: 50px;
  text-align: center;
  width: 180px;
  cursor: pointer;
  border: none;
  transition: all 0.3s;
  position: relative;
  background-color: #10171866;
  margin-bottom: 15px;
}
.extraButton span {
  transition: all 0.3s;
}
.extraButton::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: rgba(255, 255, 255, 0.5);
  border-bottom-color: rgba(255, 255, 255, 0.5);
  transform: scale(0.1, 1);
}
.extraButton:hover span {
  letter-spacing: 2px;
}
.extraButton:hover::before {
  opacity: 1;
  transform: scale(1, 1);
}
.extraButton::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.1);
}
.extraButton:hover::after {
  opacity: 0;
  transform: scale(0.1, 1);
}

@media screen and (min-width: 800px) and (max-width: 1500px) {
  .pl__listContainer {
    margin-top: 105px;
    width: 97%;
  }

  .pl__columnsContainer {
    flex-direction: column;
  }

  li {
    line-height: 30px;
    font-size: 21px;
  }
  .pl__columnsContainerFirst {
    flex-direction: row;
  }
}

@media screen and (min-width: 1600px) {
  li {
    line-height: 40px;

    font-size: 21px;
  }
  .pl__listContainer {
    width: 97%;
  }
  .pricelist__container {
    font-size: 22px;
  }
  .pl__extraContainer {
    width: 68%;
  }
}

@media screen and (max-width: 1000px) {
  li {
    font-size: 18px;
  }
  .extraText {
    font-size: 26px;
  }
  .extraButton {
    font-size: 25px;
  }
}
@media screen and (max-width: 500px) {
  .pl__listContainer {
    margin-top: 100px;
    width: 100%;
  }
  .pl__list {
    flex-direction: column;
  }
  .pl__columnsContainer {
    display: flex;
    flex-direction: column;
  }
  li {
    font-size: 16px;
    line-height: 23px;
  }
  .pl__itemListSecond {
    padding-top: 0;
  }
  .pl__extraContainer {
    width: 100%;
    margin-bottom: 40px;
  }
  .extraButton {
    font-size: 23px;
  }
  .extraText {
    font-size: 23px;
  }
  .priceListBox {
    align-items: start;
  }
}
@media screen and (min-width: 500px) and (max-width: 800px) {
  img {
    padding-left: 10px;
  }
  .pl__listContainer {
    margin-top: 100px;
    width: 99%;
  }
  .pl__list {
    width: 98%;
  }
  .pl__columnsContainer {
    display: flex;
    flex-direction: column;
  }
  ul {
    padding-left: 10px;
  }
}

@media screen and (max-width: 700px) {
  .pricesWrp {
    flex-direction: column;
  }
  .pricesNav {
    margin: 0 auto;
    width: 90%;
  }
  .priceListBox {
    width: 90%;
    margin: 0 auto 0;
  }
  .shoesList {
    display: flex;
    flex-direction: column;
  }
  .pricelistItem {
    text-align: center;
  }
}
