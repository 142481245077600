.backgroundImg {
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: all 0.5s;
  overflow: hidden;
  margin: 0;
  padding: 0;
  filter: brightness(0.5);
}
.spinnerWrp {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcfcfc;
  background-image: linear-gradient(34deg, #111112 11%, #0c031d 100%);
}

.mainTitle {
  z-index: 1;
  width: 90%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.mainTitleText {
  font-family: 'Russo One', sans-serif;

  text-transform: uppercase;
  font-weight: 800;
  font-size: 4vw;
  color: #efeceb;
  margin-top: 80px;
  margin-bottom: 0;
  letter-spacing: 4px;
  text-shadow: -7px -6px 7px #000000;
}

.mainAboutTitle {
  width: max-content;
  font-size: 3.7vw;
  margin: 50px auto 0 45%;
  color: #ffffff;
  font-family: 'Alice', serif;
  font-weight: 700;
}
.mainList {
  margin-left: auto;
  text-align: end;
}
.mainListItem {
  font-family: 'Alice', serif;
  font-size: 1.9vw;
  font-weight: 600;
  margin-bottom: 15px;
  color: #fbd9d3;
}
@media screen and (min-width: 1900px) {
  .mainListItem {
    margin: 50px 0;
  }
}
@media screen and (max-width: 768px) {
  .mainTitle {
    justify-content: space-around;
  }

  .mainTitleText {
    font-size: 7vw;
  }
  .mainListItem {
    font-size: 3vw;
  }

  .mainAboutTitle {
    font-size: 6vw;
    margin: 0px auto 0 45%;
  }
}

@media screen and (max-width: 500px) {
  .mainTitle {
    justify-content: space-around;
  }

  .mainTitleText {
    font-size: 8vw;
  }
  .mainListItem {
    font-size: 3.5vw;
  }

  .mainAboutTitle {
    font-size: 7vw;
    margin: 0px auto 0 45%;
  }
}
