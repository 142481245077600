.navigation {
  z-index: 2;
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  left: 0px;
  top: 0;
  background: linear-gradient(
    180deg,
    #050404 -31.68%,
    rgba(8, 8, 14, 0.2) 100%
  );
  box-shadow: 0px 0px 32px 10px #03020ebf;
}
.downLine {
  position: absolute;
  width: 95%;
  height: 90%;
  border-bottom: 2px solid #d36c6c;
  left: 2.5%;
  filter: blur(1px);
  z-index: 1;
}
.downLineUp {
  position: absolute;
  width: 95%;
  height: 90%;
  border-bottom: 2px solid #d36c6c;
  left: 2.5%;
  z-index: 1;
}
.list__item,
.list__link {
  font-family: 'Josefin Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  transition: all 0.3s;
  z-index: 2;
  position: relative;
}

.navigation__lvru {
  background: none;
  border: none;
  position: absolute;
  left: 2%;
  transition: 1s;
  font-size: 15px;
  font-weight: 700;
  color: #a5e8d3;
  opacity: 0.5;
  z-index: 2;
}

.navigation__lvru:hover {
  opacity: 1;
  transform: scale(1.1);
}

.active__link {
  font-family: 'Josefin Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 28px;
  text-align: center;
  color: #d36c6c;

  /* border-bottom: 2px solid #00dd77; */
}

li,
a {
  list-style-type: none;
  text-decoration: none;
}

.list {
  padding-left: 20px;
  margin: 20px 2.5% 20px 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

@media screen and (max-width: 767px) {
  .active__link {
    font-size: 20px;
  }
  .list__link {
    font-size: 18px;
  }

  .navigation {
    height: min-content;
  }
}

@media screen and (max-width: 767px) and (max-height: 500px) {
  .list__link {
    font-size: 15px;
  }

  .active__link {
    font-size: 17px;
  }
}

@media screen and (min-width: 1900px) {
  .list__link {
    font-size: 35px;
  }
  .active__link {
    font-size: 37px;
  }
}
