.backgroundImg {
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.5s;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.spinnerWrp {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcfcfc;
  background-image: linear-gradient(34deg, #111112 11%, #0c031d 100%);
}

.contacts {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.contacts__mapButtonOpen {
  margin: 0;
  border-radius: 0% 100% 100% 0% / 50% 50% 50% 50%;
  width: 20px;
  height: 89px;
  background-color: rgb(0 0 0 / 85%);

  transition: 0.7s;
  padding-left: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
  border-top-width: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.contacts__mapButtonClose {
  border-radius: 100% 0% 0% 100%/ 50% 50% 50% 50%;
  width: 20px;
  height: 89px;
  background-color: rgb(0 0 0 / 85%);
  transition: 0.7s;
  padding-left: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
  border-top-width: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-left: -25px;
  z-index: 2;
}

.contacts__mapWrp {
  padding-top: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 1s;
  margin-left: -5px;
}

.contacts__map {
  transition: 0.4s;
  margin-right: 0;
  width: 800px;
  height: 400px;
  background: rgba(11, 5, 5, 0.69);
  border: 5px solid rgba(0, 0, 0, 0.53);
  border-radius: 0 30px 30px 0;
}

.contacts__apiMap {
  opacity: 0.8;
  border-radius: 0 30px 30px 0;
}

.contactForm {
  height: 400px;
  width: 100%;
  background: #000000d4;
  border: 2px solid #3e3c3c52;
  box-shadow: 0px 0px 25px 10px #000000cc;
  margin: 40px 0;
  position: relative;
}

.contactFormDecor {
  position: absolute;
  z-index: 0;
  border: 3px solid #05e1e1;
  width: 98%;
  height: 90%;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  filter: blur(2px);
}
.contactFormDecorUp {
  width: 98%;
  height: 90%;
  border: 2px solid #05e1e1;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.inputWrapper {
  display: flex;
  margin: 75px 100px;
  justify-content: space-between;
}

form {
  position: relative;
  z-index: 2;
}

.contactInput {
  width: 300px;
  background: none;
  border: 0;
  border-bottom: 2px solid #938686;
  color: white;
  font-size: 18px;
  transition: all 1s;
}

.contactMessage {
  width: 500px;
}

.contactButton {
  margin: 0px 100px 75px;
  background: none;
  border: none;
  text-transform: uppercase;
  font-size: 21px;
  color: white;
  transition: all 0.5s;
}
.contactButton:hover {
  color: #05e1e1;
  transform: scale(1.1);
}

.contactInput:after {
  transition: all ease-in-out 0.2s;
  background: none repeat scroll 0 0 #05e1e1;
  content: '';
  display: block;
  height: 3px;
  width: 0;
}
.contactInput:hover:after {
  width: 100%;
}
input:focus {
  outline: none;
  border-bottom: 2px solid #05e1e1;
}

.contactFormMiddle {
  margin: 75px 100px;
}

.contacts__listLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  text-decoration: none;
  color: #42cad0;
  transition: 0.4s;
  font-size: 21px;
}

.contacts__listLink:hover {
  transform: scale(1.2);
}

.contacts__list {
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: flex-end;
}

.contacts__linksList {
  margin-top: 100px;
  padding-right: 150px;
  background: rgba(0, 0, 0, 0.67);
  border: 5px solid rgba(0, 0, 0, 0.16);
  border-radius: 30px 0 0 30px;
}

.contacts__address {
  color: white;
  opacity: 0.7;
  font-size: 17px;
  margin: 0 0 15px 0;
  padding: 0;
}
.contacts__pointerWrp {
  transform: matrix(1, 0, 0, 1, -9, -37);
  transition: 1s;
}

.contacts__mapPointer {
  width: 96px;
  height: 50px;
  transform: rotate(280deg);
}

.contacts__mapPointerTxt {
  transform: matrix(1, 0, 0, 1, 90, -14);
  font-size: 20px;
  color: black;
  font-weight: 700;
}
@media screen and (max-width: 1200px) {
  .contactInput {
    width: 200px;
  }
  .contactMessage {
    width: 500px;
  }
}

@media screen and (max-width: 900px) {
  .inputWrapper,
  .contactFormMiddle {
    margin: 50px 50px;
  }
  .contactButton {
    margin: 0px 50px 50px;
  }
  .contactForm {
    height: 300px;
  }
}

@media screen and (max-width: 767px) {
  .contacts {
    height: 100%;
    min-height: 100vh;
    width: 100%;
    margin: 0px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .contacts__mapWrp {
    padding-top: 100px;
    width: 110%;
    max-width: 700px;
  }
  .contacts__apiMap {
    width: 100%;
    max-width: 700px;
  }

  .contacts__list {
    /* position: absolute; */
    right: -5px;
    bottom: 10px;
    width: 100%;
  }
  .contacts__linksList {
    margin-bottom: 0;
    padding-right: 0;
  }
  .inputWrapper {
    margin: 0px 40px;
  }
  .contactFormMiddle {
    margin: 40px 40px;
  }
  .contactButton {
    margin: 0px 40px 40px;
    font-size: 18px;
  }
  .contactForm {
    height: max-content;
  }
  .inputWrapper {
    flex-direction: column;
  }
  .contactInput {
    margin: 50px 0 0;
    font-size: 16px;
  }
  .contactMessage {
    width: 350px;
  }
}

@media screen and (max-height: 500px) {
  .contacts {
    height: 100%;
    min-height: 100vh;
    width: 100%;
    margin: 0px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .contacts__mapWrp {
    padding-top: 100px;
    width: 110%;
    max-width: 700px;
  }
  .contacts__apiMap {
    width: 100%;
    max-width: 700px;
  }
  .contacts__list {
    width: 100%;
    margin: 50px 0 50px 50px;
  }
  .contacts__linksList {
    margin-bottom: 0;
    padding-right: 100px;
  }
}
